//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
$primary: if(isDarkMode(), #222829, #793CFB);
$primary-active: if(isDarkMode(), #222829, #6923ff);
$primary-light: if(isDarkMode(), #793CFB, #222829);
$primary-inverse: #ffffff;

// Success
$success: if(isDarkMode(), #8ae58d, #006603);
$success-active: if(isDarkMode(), #8ae58d, #006603);
$success-light: if(isDarkMode(), #006603, #8ae58d);
$success-inverse: #ffffff;

// Info
$info: if(isDarkMode(), #9df, #046);
$info-active: if(isDarkMode(), #9df, #046);
$info-light: if(isDarkMode(), #046, #9df);
$info-inverse: #ffffff;

// Danger
$danger: if(isDarkMode(), #ffad99, #661400);
$danger-active: if(isDarkMode(), #ffad99, #661400);
$danger-light: if(isDarkMode(), #661400, #ffad99);
$danger-inverse: #ffffff;

// Warning
$warning: if(isDarkMode(), #ffeb99, #665200);
$warning-active: if(isDarkMode(), #ffeb99, #665200);
$warning-light: if(isDarkMode(), #665200, #ffeb99);
$warning-inverse: #ffffff;
